import { Injectable } from '@angular/core';
import { FabLab } from '../models/fablab';
import { Router, NavigationStart } from '@angular/router';
import { dummyFablabs } from '../datasources/dummydb';
import { ProjectsDatasource } from '../datasources/projects.datasource';
import { MachinesDatasource } from '../datasources/machines.datasource';
import { Machine } from '../models/machine';
import { Project } from '../models/project';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  fablabs: FabLab[];
  public mainAnimation = true;

  constructor(
    private router: Router,
    private pd: ProjectsDatasource,
    private md: MachinesDatasource
  ) {
    this.fablabs = dummyFablabs;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url != 'inicio') {
          this.mainAnimation = false;
        }
      }
    });
  }

  public getLabWithId(id: string) {
    return this.fablabs.find((item: FabLab) => {
      return item.id == id;
    });
  }

  // private fetchInfo(): void {
  //   this.fablabs.forEach(item => {
  //     if (item.apiUrl) {
  //       this.pd.fetchProjects(item.apiUrl).then(response => {
  //         item.projects = response;
  //         item.projects.forEach(project => {
  //           this.pd.fetchProjectImages(item.apiUrl, project.id).then(gallery => {
  //             project.imageGallery = gallery;
  //           });
  //         })
  //       });
  //       this.md.fetchMachines(item.apiUrl).then(response => {
  //         item.machines = response;
  //         item.machines.forEach(machine => {
  //           this.md.fetchMachineImages(item.apiUrl, machine.id).then(gallery => {
  //             machine.imageGallery = gallery;
  //           })
  //         })
  //       })
  //     }
  //   })
  // }

  // Project functions 

  public fetchStaticProjects(fablab: FabLab, page: number, limit: number): any {
    let totalPages = Math.ceil(fablab.projects.length / limit);
    let start = (page - 1) * limit;
    let end = start + limit;
    let results = fablab.projects.slice(start, end);
    return {
      totalPages: totalPages,
      gallery: results
    }
  }

  public fetchProjects(fablab: FabLab, page: number, limit: number): Promise<any> | any {
    return this.pd.fetchProjects(fablab.apiUrl, page, limit)
  }

  public fetchProject(fablab: FabLab, slug: string): Promise<Project | null> {
    return this.pd.fetchProject(fablab.apiUrl, slug).then(project => {
      if (project) {
        return this.pd.fetchProjectImages(fablab.apiUrl, project.id).then(gallery => {
          project.imageGallery = gallery;
          return project;
        });
      } else {
        return null;
      }
    });
  }

  // Machine  functions 

  public fetchStaticMachines(fablab: FabLab, page: number, limit: number): any {
    let totalPages = Math.ceil(fablab.machines.length / limit);
    let start = (page - 1) * limit;
    let end = start + limit;
    let results = fablab.machines.slice(start, end);
    return {
      totalPages: totalPages,
      gallery: results
    }
  }

  public fetchMachines(fablab: FabLab, page: number, limit: number): Promise<any> | any {
    return this.md.fetchMachines(fablab.apiUrl, page, limit)
  }

  public fetchMachine(fablab: FabLab, slug: string): Promise<Machine | null> {
    return this.md.fetchMachine(fablab.apiUrl, slug).then(machine => {
      if (machine) {
        return this.md.fetchMachineImages(fablab.apiUrl, machine.id).then(gallery => {
          machine.imageGallery = gallery;
          return machine;
        });
      } else {
        return null;
      }
    });
  }
}
