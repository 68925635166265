import * as moment from 'moment';

export class Project {
  id?: number;
  slug?: string;
  name?: string;
  description?: string;
  image?: string;
  imageGallery?: string[];
  date?: moment.Moment;
  galleryLink?: string;
}
