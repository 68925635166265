import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Machine } from '../models/machine';

@Injectable({
  providedIn: 'root'
})
export class MachinesDatasource {

  constructor(
    private http: HttpClient
  ) { }

  public fetchMachines(apiUrl: string, page?: number, limit?: number): Promise<any> {
    let p = `page=${page}&per_page=${limit}`;
    let params = new HttpParams({fromString: p});
    return this.http.get(`${apiUrl}maquina`, {params: params, observe: 'response'}).toPromise()
      .then((response) => {
        let totalPages = response.headers.get('X-WP-TotalPages');
        let res = [];
        let body = response.body as any[]
        body.forEach(item => {
          let m = new Machine();
          m.id = item.id;
          m.name = item.title.rendered;
          m.slug = item.slug;
          m.description = item.uagb_excerpt; 
          m.image = item.uagb_featured_image_src.large[0];
          res.push(m);
        })
        return {
          totalPages: totalPages,
          gallery: res
        };
      })
      .catch(error => {
        console.log(error);
        return [];
      })
  }

  public fetchMachine(apiUrl: string, slug: string): Promise<Machine | null> {
    let p = `slug=${slug}`;
    let params = new HttpParams({fromString: p});
    return this.http.get(`${apiUrl}maquina`, {params}).toPromise()
      .then((response: any[]) => {
        if (response.length > 0) {
          let item = response[0];
          let m = new Machine();
          m.id = item.id;
          m.name = item.title.rendered;
          m.slug = item.slug;
          m.description = item.uagb_excerpt; 
          m.image = item.uagb_featured_image_src.large[0];
          return m;
        } else {
          return null;
        }
      })
      .catch(error => {
        console.log(error);
        return null;
      })
  }

  public fetchMachineImages(apiUrl: string, id: number): Promise<string[]> {
    let params = new HttpParams().set('parent', id);
    return this.http.get(`${apiUrl}media`, {params}).toPromise()
      .then((response: any[]) => {
        let r = response.filter(item => item.media_type == 'image');
        return r.map(item => item.source_url);
      })
      .catch(error => {
        console.log(error);
        return [];
      })
  }
}
  