import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebService } from './services/web.service';
import { MachinesDatasource } from './datasources/machines.datasource';
import { ProjectsDatasource } from './datasources/projects.datasource';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    MachinesDatasource, ProjectsDatasource
  ]
})
export class ModelModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ModelModule,
      providers: [
        WebService
      ]
    };
  }
}
