import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Project } from '../models/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectsDatasource {

  constructor(
    private http: HttpClient
  ) { }

  public fetchProjects(apiUrl: string, page?: number, limit?: number): Promise<any> {
    let p = `page=${page}&per_page=${limit}`;
    let params = new HttpParams({fromString: p});
    return this.http.get(`${apiUrl}proyecto`, {params: params, observe: 'response'}).toPromise()
      .then((response) => {
        let totalPages = response.headers.get('X-WP-TotalPages');     
        let res = [];
        let body = response.body as any[]
        body.forEach(item => {
          let p = new Project();
          p.id = item.id;
          p.slug = item.slug;
          p.name = item.title.rendered;
          p.description = item.uagb_excerpt;
          p.date = item.date;
          // p.description = item.content.rendered;
          p.image = item.uagb_featured_image_src.large[0];
          res.push(p);
        });
        return {
          totalPages: totalPages,
          gallery: res
        };
      })
      .catch(error => {
        console.log(error);
        return [];
      })
  }

  public fetchProject(apiUrl: string, slug: string): Promise<Project | null> {
    let p = `slug=${slug}`;
    let params = new HttpParams({fromString: p});
    return this.http.get(`${apiUrl}proyecto`, {params}).toPromise()
      .then((response: any[]) => {
        if (response.length > 0) {
          let item = response[0];
          let p = new Project();
          p.id = item.id;
          p.slug = item.slug;
          p.name = item.title.rendered;
          p.description = item.uagb_excerpt;
          p.date = item.date;
          // p.description = item.content.rendered;
          p.image = item.uagb_featured_image_src.large[0];
          return p;
        } else {
          return null;
        }
      })
      .catch(error => {
        console.log(error);
        return null;
      })
  }

  public fetchProjectImages(apiUrl: string, id: number): Promise<string[]> {
    let params = new HttpParams().set('parent', id);
    return this.http.get(`${apiUrl}media`, {params}).toPromise()
      .then((response: any[]) => {
        let r = response.filter(item => item.media_type == 'image');
        return r.map(item => item.source_url);
      })
      .catch(error => {
        console.log(error);
        return [];
      })
  }
}
